import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled, thd, Grid, Row, Col } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

import { withSettings } from 'containers/WebApp/SettingsContext';

import AnchorProfessionals from './images/logo-footer-anchor_professionals-@2x.jpg';
import Anchor from './images/logo-footer-anchor-@2x.jpg';
import Bega from './images/logo-footer-bega-@2x.jpg';
import Mainland from './images/logo-footer-mainland-@2x.jpg';
import Perfect from './images/logo-footer-perfect_italiano-@2x.jpg';
import WesternStar from './images/logo-footer-western_star-@2x.jpg';
import HolyCow from './images/logo-holy-cow.png';

const FooterWrapper = styled.footer`
  background-color: #fff;
  border-top: 5px solid ${thd('primary', 'gold')};
`;

const FooterContainer = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 1rem 0 0.5rem 0;
  ${up(
    'md',
    css`
      flex-direction: row;
      justify-content: space-between;
    `
  )};
  p {
    font-size: 14px;
    font-weight: bold;
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #031b2d;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #000;
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .logos {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      text-align: center;
      padding: 5px;
      border: 0;
      ${up(
        'md',
        css`
          text-align: left;
        `
      )};
    }
  }
`;

const year = () => {
  const d = new Date();
  return d.getFullYear();
};

const Footer = ({ siteName }) => (
  <FooterWrapper>
    <FooterContainer>
      <Grid>
        <Row justifyContent="center">
          <Col className="text--center">
            <img
              src={AnchorProfessionals}
              alt="Anchor Food Professionals"
              width="180"
            />
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col className="text--center">
            <ul className="logos">
              <li>
                <img src={WesternStar} alt="Western Star" width="100" />
              </li>
              <li>
                <img src={Perfect} alt="Perfect Italiano" width="100" />
              </li>
              <li>
                <img src={Mainland} alt="Mainland" width="100" />
              </li>
              <li>
                <img src={Bega} alt="Bega" width="100" />
              </li>
              <li>
                <img src={Anchor} alt="Anchor" width="100" />
              </li>
              <li>
                <img src={HolyCow} alt="Holy Cow! Cheese Co." width="100" />
              </li>
            </ul>
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col className="text--center">
            <ul>
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/sitemap">Sitemap</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col className="text--center">
            <p className="text--center">
              &copy; Anchor
              <sup>™</sup> Food Professionals {year()}
            </p>
          </Col>
        </Row>
      </Grid>
    </FooterContainer>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
};

Footer.defaultProps = {
  siteName: 'Lori Program Default',
};

export default withSettings(Footer);
